form.champs-search-form{
  background: rgb(200, 198, 198);
  padding-top:18px;
  padding-bottom:18px;
}
form.champs-search-form>select{
  border-radius:9px;
}

form.nav-is-above{
  
  padding-top:36px;

}

.reset-filters-box{
  display:inline-block;
  margin:5px 10px;
  padding: 5px;
  background-color:white;
}

h2.nav-is-above{
  padding-top: 18px;
}

.search-form-champ-name-box{
  margin-top:15px;
}

.champs-search-form select{
  padding:15px;
}

#search-form-champ-origin{
  margin:5px 10px;
}
#search-form-champ-class{
  margin:5px 10px;

}
#search-form-champ-price{
  margin:5px 10px;

}

#search-form-champ-name{
  padding:10px;
}