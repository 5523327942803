nav,footer{
  padding:1rem;
  background-color:rgb(34, 33, 33);
  /* 
  background:rgba(0,0,0,0.9); */
}


nav{
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:100;
  height:30px;
  width:100%;
  display:flex;
  align-items: center;
  justify-content:space-between;
}

/* .home-link{
  margin-left:10px;
} */

.nav-links>a{
  margin-left:10px;
}

.nav-link{
  text-decoration:none;
}

.active-nav-link{
  color:gold;/* 
  color:#FBAF47; */
  font-weight:600;
}

.inactive-nav-link{/* 
  color:rgb(144, 165, 233); */
  color:#E8E5F3;
  font-weight:600;
}
.rightmost-nav-link{
  margin-right:0px;
}

@media (hover:hover) {
  .inactive-nav-link:hover{
    color:rgba(255,215,0.6)

  }
}