.flex-box-complete-item-info{
  display:inline-flex;
  align-items:center;/* 
  justify-content:center; */
  margin:0;

  padding: 2px 10px;
  text-align:left;
  width:300px;

}

.item-info-box{
  display:flex;
  flex-direction:column;
  justify-content:center;
}

.item-desc{/* 
  max-width:300px; */
  padding:3px 3px;
}

@media screen and (min-width:500px){
  .flex-box-complete-item-info{
    width:400px;
  }
}

@media screen and (min-width:760px){
  .flex-box-complete-item-info{
    width:500px;
  }
}