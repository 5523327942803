.champs-search .view-type{
  display:inline-block;
  position:relative;
  flex-basis:50%;
}

.champs-search .view-type-box{
  display:flex;
}

.champs-search-h2{
  position:relative;
  margin:0px;
  padding:1em 0em;
}

.champs-search .filter-view-instructions{
  font-weight:normal;
  font-size:50%;
  color: yellow;
}

.champs-search .selected{
  background-color:yellow;
}

.champs-search .unselected{
  background-color:gray;
}

.champs-search .unselected:hover{
  background-color:rgba(255, 255, 0, 0.65);
}

.champs-search{
  background-color: rgb(23, 22, 22);
  padding-bottom:100px;
}