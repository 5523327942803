
.build-stats-ultimate{
  padding:10px;/* 
  flex-basis:50%; *//* 
  flex-grow:1; */
  font-size:70%;
  display:flex;
  flex-wrap:wrap;
}

.build-ult-desc{
  flex-basis:60%;
  padding:5px;
}
.ult-prop-list{
  flex-basis:40%;
  max-width:300px;
  font-weight:600;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  padding:5px;
}
/* .ult-prop{
  margin-right:15px;
} */
.adding-final{
  display:inline;
  color:magenta;
  font-weight:600;
}
.adjusted-final{
  font-weight:700;
}

@media screen and (min-width:760px){
  .build-stats-ultimate {
    font-size:100%;
  }
}