.Dashboard{
  padding:18px 20px;
  text-align:left;
  padding-top:30px;
}
.display-account-builds-box{
  background-color:rgba(155, 200, 87,0.8);
  padding-left:15px;
  padding-right:15px;
  padding-bottom:10px;
  padding-top:1px;
  margin-top:20px;
  border-radius:10px;
}