
.synergies-box{
  font-size:70%;
}

.colors{
  border:2px solid rgb(124, 150, 158);
}

.build-display-synergy{
  display:inline-flex;
  align-items:center;
  justify-content:center;
  background-color:rgba(0,0,0,0.5);/* 
  margin-right:30px;
  margin-bottom:10px; */
  margin:5px 5px;
  padding: 0px .5em;
}

.build-display-synergy>div{
  display:inline-flex;
}


.build-trait-effect{
  flex-direction:column;
}

.build-trait-effect>.trait-progression>.opaque{
  opacity:0.5;
}

.build-trait-count{
  font-size:125%;
  margin:0em .2em;
  padding:.2em;
  background-color: rgb(26, 25, 25);
}

.build-traits-icon{
  width:15px;
}

.bronze-trait{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image:url('../../img/dbronze.png');
}

.silver-trait{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image:url('../../img/dsilver.png');
}

.gold-trait{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image:url('../../img/dgold.png');
}

.no-trait{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image:url('../../img/no.png');
}

.build-traits-icon-box{
  padding:5px;
  align-items:center;
}

.syn-desc-opaque{
  opacity:.5;
}
.build-display-synergy-expand p{
  margin-top:0px;
}
.build-display-synergy-expand{
  margin-left: 15px;
  padding:10px 0px;
  flex-direction:column;
  max-width:400px;
}

@media screen and (min-width:550px){
  .synergies-box{
    font-size:84%;
  }
  .synergies-box>.build-display-synergy{
    margin:5px 10px;
  }
  .synergies-box>.build-display-synergy>.build-traits-icon-box{
    padding:6px;
  }
  .synergies-box>.build-display-synergy>.build-traits-icon-box>.build-traits-icon{
    width:18px;
  }
}

@media screen and (min-width:760px){
  .synergies-box{
    font-size:90%;
  }
  .synergies-box>.build-display-synergy{
    margin:5px 15px;
  }
  .synergies-box>.build-display-synergy>.build-traits-icon-box{
    padding:7px;
  }
  .synergies-box>.build-display-synergy>.build-traits-icon-box>.build-traits-icon{
    width:21px;
  }
}

@media screen and (min-width:1400px){
  .synergies-box{
    font-size:100%;
  }
  .synergies-box>.synergy-header{
    font-size:18.72px;
  }
  .synergies-box>.build-display-synergy{
    margin:5px 15px;
  }
  .synergies-box>.build-display-synergy>.build-traits-icon-box{
    padding:8px;
  }
  .synergies-box>.build-display-synergy>.build-traits-icon-box>.build-traits-icon{
    width:24px;
  }
}