.build-display-synergies{/* 
  background-color:rgba(66, 67, 78,.6); */
  color:gold;/* 
  padding-top:calc(30px + 1em); *//* 
  padding-top:1em;
  padding-bottom:1em; */
  padding-bottom:1em;
  position:relative;
}
.synergy-header{
  margin:0px;
}
.synergies-expand-collapse{
  position:absolute;
  font-size:90%;
  right:5px;
  top:5px;

}

.patch-version{
  position:absolute;
  font-size:90%;
  left:5px;
  top:5px;

}


/* .build-display-synergies .expand-collapse{
  font-size:90%;
}
 */