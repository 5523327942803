.expand-collapse{
  position:absolute;
  top:10px;
  right:10px;
  font-size:100%;
}

.create-builds-page{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient( rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65) ), url(/static/media/backsplash.e48a9080.jpg);
  background-repeat: repeat;
  background-position: 50% 0, 50% 0;
  background-size: 150% auto;
}

.create-builds-page h2{
  color: rgb(255, 254, 254);
}

.search-and-build-separator{
  height:30px;
  width:100%;
  background-color:red;
}