.front-page-div{
  color:white;
  font-size:1em;
  padding-bottom:100px;
}
.front-page-div h1{
  padding: 100px 0%;
}
.front-page-div section{
  min-height:200px;
  padding:50px 0%;
  max-width:550px;
  margin:auto;
}
.front-page-div .s1{
  padding-left:15%;
  padding-right:15%;

}
 
.front-page-div .header-undertext{
  font-size:50%;
  display:block;
  font-weight:normal;
}

.front-page-header{
  padding-top:50px;
  position: relative;
  display:flex;
  align-items:center;
  justify-content:center;
  color:gold;/* 
  min-height:50vw;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.65),
    rgba(0, 0, 0, 0.65)
  ), url('../../img/backsplash.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover; */
}
.front-page-header>h1{
  z-index:5;

}

.s1{/* 
  background-color:rgb(34, 33, 33); */
  /* background-image: linear-gradient(
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0.75)
  ), url('../../img/Hauntling-splash.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover; */
}

.s2{/* 
  background-color:rgb(34, 33, 33); */
  /* background-image: linear-gradient(
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0.75)
  ), url('../../img/Penguin-splash.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover; */
}

/* .front-page-header>img{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  opacity: 0.15;

} */

/* .front-page-header:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.5;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ), url('../../img/backsplash.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
} */

.front-page-div .btn{
  border:0px;
  border-radius:5px;
  display:inline-block;
  padding:1rem;
  background-color:#494627;
  margin:0px 10px;
}
.btn .nav-link{
  color:white;
}


@media screen and (min-width:760px){
  .front-page-div{
    font-size:1.25em;
  }
  .front-page-div .s1{
    padding-left:0;
    padding-right:0;
  
  }
}


@media (hover:hover) {
  .front-page-div .btn:hover{
    background-color:rgba(255,215,0,0.3)
  }
}