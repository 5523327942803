.champ-short-display{
  position:relative;
  min-width:111px;
  margin: 5px 3px;
  background-color:rgb(63, 69, 122);
  color: white;
  text-align: left;
  font-size:70%;
}
@media screen and (min-width:550px){
  .champ-short-display{
    font-size:84%;
    min-width:140px;
  }
}
@media screen and (min-width:760px){
  .champ-short-display{
    font-size:90%;
    min-width:150px;
  }
}

.search-champ-icon-box-traits-box{
  vertical-align:top;
  display:inline-flex;
  flex-direction: column;
  margin-left:5px;
}
/* .search-champ-icon-box-traits{

} */

.champ-icon-box{
  padding:5px;
}

.champ-icon-box ul{
  display:inline-block;
  margin-top:0;
  margin-bottom:0;
}

.champ-icon{
  width:36px;
}
.champ-name-box{
  background-color:rgb(117, 68, 8);
  font-size:100%;
  padding:5px 10px;
  display:flex;
  justify-content: space-between
}
.champ-name-box-name{
  float:left;
}
.champ-name-box-cost{
  float:right;
}

.coins-white{
  display:inline-block;
  width:10px;
  height:10px;/* 
  background-size: cover; */
  background-repeat: no-repeat;/* 
  background-position: 50% 50%; */
  background-image:url('../../img/coins_gold.png');
}

