.login-box {
  
  z-index:2;
  height: 500px;
  width: 300px;/* 
  background: rgba(200, 200, 200, 0.88); */

  position: absolute;
  top:150px;
  
  /* margin-top: -250px; */
}


.offset1{
  stop-color:rgb(199, 175, 89);
  stop-color:#FFF0A2;
  stop-opacity:1;
}

.offset2{
  stop-color:rgb(255,215,0);
  stop-opacity:1;
}


/* .svg-gold{
} */
.svg-div{
  padding-top:70px;
  padding-left:10px;
  padding-bottom:20px;
}
.hex-gold{
  fill:rgba(255,215,0,0.6);
  fill:#FBAF47;
}
.hex-black{
  
  fill:rgba(211, 211, 211, 0.6);/* 
  fill:rgb(34, 33, 33,0.7); */
}

.login-box input{
  border-radius:5px;
  border:0;
}

@media screen and (min-width:300px){
  .login-box{
    left: calc(50% - 150px);
  }
}

@media screen and (max-width:300px){
  main{
    position:relative;
    overflow-x:scroll;
  }
}

.login-box{
padding:12px 10px;
}

.login-box .fieldset-style{
padding:0;
margin:0;
text-align:left;
border:0;
} 

.login-box .fieldset-style > legend{
margin-bottom: 2%;
}

.login-box input{
width:100%;
}
.login-box .login-sign-in{
  width:100%;
  border:0px;
  padding:0.5rem;
  border-radius:5px;
  background-color:#A2B9B1;
  background-color:#e8e5f3;
  font-weight:550;
}


@media (hover:hover){
  .login-box .login-sign-in:hover{
    background-color:rgba(162,185,177,0.8);
  }
  .create-account-from-login:hover{
    background-color:rgba(0,0,0,0.4);
  
  }
}
.login-box input{
  padding:0.5rem;
}

.login-box .login-helper{
text-align:left;
}
.login-box .forgot-login{
float:left;
}
.login-box .login-signup-link{
float:right;
}
.login-box h1.login-box-logo{
padding:0;
margin:0;/* 
position:absolute;
top: 20px;
left:67px; */
margin-bottom:25px;
}
.red{color:red;}

/* @media screen and (max-height:538px){
  main{
    min-height:700px;
  }
} */