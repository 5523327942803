.headertwo-undertext{
  font-size:80%;
  display:flex;
  font-weight:500;
  justify-content:space-evenly;
  align-items: center;
}

/* .current-build-page-new-build{
  
} */
.current-build-title{
  margin-top:0px;
  margin-bottom:0px;
  padding-top: 60px;
}

.current-build-display{
}
.current-build-page-new-build{
  color:red;
  font-weight:550;
}

.current-build-page-save-build{
  color:darkblue;
  font-weight:550;
}

.new-or-save-box{
  margin:auto;
  width: 211px;
  display:flex;
  justify-content: space-between;
}

.sweet-loading{
  padding:1px 0px;
}