.gold {
  color: orange;
}
.silver{
  color:silver;
}
.bronze{
  color:rgb(112, 26, 26);
}


.build-display-champion-card{
  position:relative;
  background-color: azure;/* 
  padding:4px 2px; */
  width:100%;/* 
  max-width:550px; */
  margin:10px auto;
  overflow-x:auto;
  overflow-y:hidden;
  font-size:70%;
}
.edit-notice{
  position:absolute;/* 
  color:rgb(238, 208, 172); */
  color:darkblue;
  top:0;
  left:2px;
  backdrop-filter: blur(3px);
  z-index:4;

}

.build-buttons{/* 
  display:inline-block; */
  padding:2px;
  font-weight:bolder;
  color:darkblue;/* 
  background-color: rgb(221,221,221);
  backdrop-filter: blur(3px); */
}

/* .add-items-button{
  justify-self:flex-start;
} */

.champion-simple-flex{
  position:relative;
  display:flex;/* 
  align-items:center;
  justify-content:space-around; */
  height: 68px;/* 
  overflow-x:auto;
  overflow-y:auto; */

}


.champion-simple-flex>div{
  margin:auto;
}


.build-champ-icon-box{
  position:relative;
  display:inline-block;
}

.build-champ-icon{
  border-radius:50%;
  width:35px;
  z-index:3;
}
.build-champ-icon-box-price1{
  z-index:4;
  position:absolute;
  bottom:2px;
  left:0px;
  border-radius:50%;
  color:white;
  padding:0px 1px;
  font-size:11px;
  width:12px;
  background-color:rgb(128,128,128);
}
.build-champ-icon-box-price2{
  z-index:4;
  position:absolute;
  bottom:2px;
  left:0px;
  border-radius:50%;
  color:white;
  padding:0px 1px;
  font-size:11px;
  width:12px;
  background-color:rgb(17,178,136);
}
.build-champ-icon-box-price3{
  z-index:4;
  position:absolute;
  bottom:2px;
  left:0px;
  border-radius:50%;
  color:white;
  padding:0px 1px;
  font-size:11px;
  width:12px;
  background-color:rgb(32,122,199);
}
.build-champ-icon-box-price4{
  z-index:4;
  position:absolute;
  bottom:2px;
  left:0px;
  border-radius:50%;
  color:white;
  padding:0px 1px;
  font-size:11px;
  width:12px;
  background-color:rgb(196,64,218);
}
.build-champ-icon-box-price5{
  z-index:4;
  position:absolute;
  bottom:2px;
  left:0px;
  border-radius:50%;
  color:white;
  padding:0px 1px;
  font-size:11px;
  width:12px;
  background-color:rgb(255,185,59);
}
.build-champ-icon-box-price7{
  z-index:4;
  position:absolute;
  bottom:2px;
  left:0px;
  border-radius:50%;
  color:white;
  padding:0px 1px;
  font-size:11px;
  width:12px;
  background: red; 
  background: -webkit-linear-gradient(left, orange , yellow, green, cyan, blue, violet); 
  background: -o-linear-gradient(right, orange, yellow, green, cyan, blue, violet); 
  background: -moz-linear-gradient(right, orange, yellow, green, cyan, blue, violet); 
  background: linear-gradient(45deg, orange , yellow, green, cyan, blue, violet);
}


.cost1{
  border: 1.5px solid rgb(128,128,128);
}
.cost2{
  border: 1.5px solid rgb(17,178,136);
}
.cost3{
  border: 1.5px solid rgb(32,122,199);
}
.cost4{
  border: 1.5px solid rgb(196,64,218);
}
.cost5{
  border: 1.5px solid rgb(255,185,59);
}

@keyframes rainbow {
  0% {border-color: red;}
  15% {border-color: orange;}
  30% {border-color: yellow;}
  45% {border-color: green;}
  60% {border-color: cyan;}
  75% {border-color: blue;}
  90% {border-color: violet;}
  100% {border-color: white;}

}

.cost7{
  border: 1.5px solid red;
  animation: rainbow 6s infinite alternate;
}


.build-display-champion-card p{
  margin:0;
  padding:0;
}

.build-display-champion-card .b1{
  flex: 1 0 75px;
}
.build-display-champion-card .b2{
  flex: 1 0 75px;
}
.build-display-champion-card .b3{
  flex: 0 0 35px;
}
.build-display-champion-card .b4{
  flex: 1 0 105px;/* 
  display:flex;
  flex-direction:column; */
}
.selecting-stars-box{
  display:flex;
  flex-wrap:wrap;
}
.selecting-stars{
  padding:5px 5px;
  margin:0px 20px;
}

/* .build-display-add-items{
} */
.build-display-item-grid-box{
  overflow-x:auto;/* 
  background-color:rgb(225, 243, 202); */
}
.build-display-item-grid{
  margin:auto;
}
.item-img-div{
  display:inline-block;
}

.build-item-icon{
  width:30px;
  margin-right:5px;
}
.grid-build-item-icon{
  
  width:26px;
  margin-right:4px;
}

.build-items-box{
  min-height:30px;
  margin:0px 0px;
}





/* .b5-remove .remove-champ-button{
  font-size:70%;
  justify-self:right;
} */

.b5-remove{
  width:10px;
  height:100%;
  color:red;
  background-color:rgb(250, 250, 250);
  justify-self:right;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
}
.b5-remove>div{
  font-weight:900;
}

/* .remove-champ-button{
  color:red;
  transform:rotate(90deg);
  -webkit-transform:rotate(90deg);
} */

.top-right{
  position:absolute;
  top:0;
  right:0px;
}

















/* .build-display-card-expansion{
  position:relative;
  margin:5px;
  padding:5px;
  padding-top:17px;
  background-color:rgb(225, 243, 202);
  color:black;
  text-align:left;
} */
.build-display-card-expansion>section{
  
  margin:5px;
  padding:5px;
  border:1px solid grey;
}
.build-display-stats{
  display:flex;
  flex-wrap:wrap;
  flex-direction:row;/* 
  justify-content:center;
  align-items:center; */
  overflow-x:auto;
}
.build-stats-table{
  border: 1px solid black;
  border-collapse: collapse;/* 
  flex-grow:1; */
  margin:auto;
}
.build-stats-table td{
  border: 1px solid black;
  padding:2px;
  min-width:40px;
}
.build-stats-table-div{
  overflow-x:auto;
  overflow-y:hidden;
}





.build-display-expanded-show-items{
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:center;
}


@media screen and (min-width:550px){
  .build-display-champion-card{
    font-size:80%;
  }
  .build-item-icon{
    width:35px;
    margin-right:5px;
  }
  .grid-build-item-icon{
    
    width:35px;
    margin-right:5px;
  }
  .champion-simple-flex{
    height: 73px;
  }
  .build-champ-icon{
    width:45px;
  }
  .cost1{
    border: 3px solid rgb(128,128,128);
  }
  .cost2{
    border: 3px solid rgb(17,178,136);
  }
  .cost3{
    border: 3px solid rgb(32,122,199);
  }
  .cost4{
    border: 3px solid rgb(196,64,218);
  }
  .cost5{
    border: 3px solid rgb(255,185,59);
  }
  .cost7{
    border: 3px solid red;
    animation: rainbow 6s infinite alternate;
  }


  .build-champ-icon-box-price1{
    bottom:3px;
    left:0px;
    font-size:14px;
    width:16px;
  }
  .build-champ-icon-box-price2{
    bottom:3px;
    left:0px;
    font-size:14px;
    width:16px;
  }
  .build-champ-icon-box-price3{
    bottom:3px;
    left:0px;
    font-size:14px;
    width:16px;
  }
  .build-champ-icon-box-price4{
    bottom:3px;
    left:0px;
    font-size:14px;
    width:16px;
  }
  .build-champ-icon-box-price5{
    bottom:3px;
    left:0px;
    font-size:14px;
    width:16px;
  }
  .build-champ-icon-box-price7{
    bottom:3px;
    left:0px;
    font-size:14px;
    width:16px;
  }

  .build-display-champion-card .b4{
    flex: 1 0 120px;
  }
}
@media screen and (min-width:760px){
  .build-items-box{
    margin:3px 0px;
  }
  .build-display-champion-card{
    font-size:90%;
  }
  .build-stats-table td{
    border: 1px solid black;
    padding:2px;
    min-width:40px;
  }
  .build-champ-icon{
    width:50px;
  }
  
  .champion-simple-flex{
    height: 90px;
  }
  .build-display-champion-card .b1{
    flex: 0 0 100px;
    max-height:90px;
  }
  .build-display-champion-card .b2{
    flex: 0 0 100px;
    max-height:90px;
  }
  .build-display-champion-card .b3{
    flex: 0 0 45px;
    max-height:90px;
  }
  .build-display-champion-card .b4{
    flex: 0 0 140px;
    max-height:90px;
  }
  .build-display-champion-card .build-stats-table-div{
    flex: 10  0 200px;
    overflow-y:auto;
    max-height:90px;
  }

  .build-display-champion-card .b5-remove{
    width:15px;
    flex: 0 0 15px;
  }
}

@media screen and (min-width:1000px){
  .build-display-champion-card{
    font-size:100%;
  }
  .build-stats-table td{
    border: 1px solid black;
    padding:2px;
    min-width:45px;
  }
}

@media screen and (min-width:1400px){
  .champion-simple-flex{
    max-height: 90px;
  }
  .build-display-champion-card .b1{
    flex: 0 0 100px;
  }
  .build-display-champion-card .b2{
    flex: 0 0 100px;
  }
  .build-display-champion-card .b3{
    flex: 0 0 45px;
  }
  .build-display-champion-card .b4{
    flex: 0 0 140px;
  }
  .build-display-champion-card .build-stats-table-div{
    flex: 1 0 400px;
    overflow-y:auto;
  }
  .build-display-champion-card .build-stats-ultimate{
    flex: 3 0;
    overflow-y:auto;
    padding:5px 5px;
    height: 90px;
  }
  .build-display-champion-card .build-stats-ultimate .ult-prop-list{
    height:100%;
    overflow-y:auto;
  }
  .build-display-champion-card .build-stats-ultimate .build-ult-desc{
    height:100%;
    overflow-y:auto;
  }
  .build-display-champion-card .b5-remove{
    width:15px;
    flex: 0 0 15px;
  }

}